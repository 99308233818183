<template>
  <div class="pa-0 ma-0">
    <supplier-edit-dialog
      v-model="supplierDialog"
      :silo="editSilo"
      @close="filterSilos"
    />
    <v-text-field
      v-model="searchTerm"
      prepend-icon="mdi-magnify"
      :label="$t('common.search')"
      @keyup="() => filterSilos()"
      class="mx-4"
    />

    <v-data-table
      :headers="headers"
      :items="filteredSilos"
      single-expand
      item-key="siloId"
      show-expand
      style="width: 100%"
      :items-per-page="20"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td class="pa-0" :colspan="headers.length">
          <silo-list-compact-data
            :siloId="item.siloId"
            v-if="item.siloId != undefined"
          />
        </td>
      </template>

      <template v-slot:[`item.volumePrecentile`]="{ item }">
        <precentile-indicator
          :precentile="
            item.lastValue == undefined
              ? 0
              : (item.lastValue.volumeLeftM /
                  item.property.totalVolumeMRounded) *
                100
          "
        />
      </template>

      <template v-slot:[`item.alarmActive`]="{ item }">
        <circle-indicator
          :status="item.haveActiveAlarm"
          :latest-data-date="item.lastValue ? item.lastValue.date : null"
        />
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ humanDate(item.createdAt) }}
      </template>

      <template v-slot:[`item.suppliers`]="{ item }">
        <span v-if="isSupplier">{{ userCompanyName }}</span>
        <span v-else>{{
          item.suppliers.length > 0 ? item.suppliers.join(", ") : "N/A"
        }}</span>
      </template>

      <template v-slot:[`item.propertyVolume`]="{ item }">
        <span v-if="item.contents == null"
          >{{ item.property.totalVolumeMRounded.toFixed(1) }}m³</span
        >
        <span v-else>
          {{ item.property.totalVolumeMRounded.toFixed(1) }}m³ /
          {{
            (
              item.property.totalVolumeMRounded *
              item.contents.weightByCubicMeter
            ).toFixed(1)
          }}ton
        </span>
      </template>

      <template v-slot:[`item.lastValue.volumeLeftM`]="{ item }">
        <span v-if="!item.lastValue">N/A</span>
        <span v-else-if="item.lastValue && item.contents == null">{{
          item.lastValue.volumeLeftM.toFixed(1) + "m³"
        }}</span>
        <span v-else
          >{{ item.lastValue.volumeLeftM.toFixed(1) + "m³" }} /
          {{
            (
              item.contents.weightByCubicMeter * item.lastValue.volumeLeftM
            ).toFixed(1)
          }}ton /
          {{
            (item.lastValue == undefined
              ? 0
              : (item.lastValue.volumeLeftM /
                  item.property.totalVolumeMRounded) *
                100
            ).toFixed(0)
          }}
          %</span
        >
      </template>

      <template v-slot:[`item.lastValue.maxToFillM`]="{ item }">
        <span v-if="!item.lastValue">N/A</span>
        <span v-else-if="item.lastValue && item.contents == null">{{
          (
            item.property.totalVolumeMRounded - item.lastValue.volumeLeftM
          ).toFixed(1) + "m³"
        }}</span>
        <span v-else>
          {{
            (
              item.property.totalVolumeMRounded - item.lastValue.volumeLeftM
            ).toFixed(1) + "m³"
          }}
          /
          {{
            (
              item.contents.weightByCubicMeter *
              (item.property.totalVolumeMRounded - item.lastValue.volumeLeftM)
            ).toFixed(1)
          }}ton
        </span>
      </template>

      <template v-slot:[`item.contents.name`]="{ item }">
        <div>
          {{ item.contents ? item.contents.name : "N/A" }}
          <v-btn
            v-if="isSupplier"
            class="ml-2"
            icon
            x-small
            color="primary"
            @click="editSupplierSilo(item)"
          >
            <v-icon>mdi-pen</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:[`item.comment`]="{ item }">
        <div>
          {{ item.comment }}
          <v-btn
            v-if="isSupplier"
            class="ml-2"
            icon
            x-small
            color="primary"
            @click="editSupplierSilo(item)"
          >
            <v-icon>mdi-pen</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          icon
          @click="() => $router.push(`/silos/${item.siloId}`)"
          v-if="permitted('Silos.Update') && !item.isShared"
        >
          <v-icon color="primary">mdi-pen</v-icon>
        </v-btn>

        <v-btn
          icon
          @click="
            () => {
              removeDialog = true;
              selectedId = item.siloId;
            }
          "
          v-if="permitted('Silos.Delete') && !item.isShared"
        >
          <v-icon color="error">mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <delete-dialog
      :dialog="removeDialog"
      v-on:cancel-dialog="removeDialog = false"
      v-on:confirm-delete="remove()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import DeleteDialog from "../common/DeleteDialog.vue";
import SiloListCompactData from "./SiloListCompactData.vue";
import CircleIndicator from "../common/CircleIndicator.vue";
import PrecentileIndicator from "../common/PrecentileIndicator.vue";
import SupplierEditDialog from "./SupplierEditDialog.vue";

const siloSupplierDefaults = {
  comment: null,
  siloPropertyId: null,
};

export default {
  components: {
    SiloListCompactData,
    CircleIndicator,
    PrecentileIndicator,
    DeleteDialog,
    SupplierEditDialog,
  },

  name: "SiloList",

  data() {
    var headers = [
      { text: this.$t("silos.fields.alarmActive"), value: "alarmActive" },
      { text: this.$t("silos.fields.name"), value: "name" },
      { text: this.$t("silos.fields.location"), value: "location" },
      { text: this.$t("silos.fields.suppliers"), value: "suppliers" },
      { text: this.$t("silos.fields.device"), value: "device.identifier" },
      { text: this.$t("silos.fields.contents"), value: "contents.name" },
      {
        text: this.$t("silos.fields.volumePrecentile"),
        value: "volumePrecentile",
      },
      {
        text: this.$t("silos.fields.volumeLeftM"),
        value: "lastValue.volumeLeftM",
      },
      {
        text: this.$t("silos.fields.maxToFillM"),
        value: "lastValue.maxToFillM",
      },
      {
        text: this.$t("silos.fields.propertyVolume"),
        value: "propertyVolume",
      },
      { text: this.$t("silos.fields.comment"), value: "comment" },
      { text: this.$t("silos.fields.createdAt"), value: "createdAt" },
      { text: "", value: "actions" },
    ];

    // Add Company to SuperAdmins list
    if (this.isSuperAdmin())
      headers.splice(3, 0, {
        text: this.$t("silos.fields.company"),
        value: "companyName",
      });

    return {
      filteredSilos: [],
      supplierPayload: siloSupplierDefaults,
      searchTerm: "",
      removeDialog: false,
      selectedId: null,
      headers: headers,
      supplierDialog: false,
      editSilo: null,
    };
  },

  computed: {
    ...mapState("silos", ["silos"]),
    ...mapGetters("silos", ["getSiloById"]),
    ...mapGetters("users", ["isSupplier", "userCompanyName"]),
  },

  methods: {
    ...mapActions("silos", ["getSilos", "deleteSilo", "removeInvitedSilo"]),
    ...mapActions("alarms", ["getAlarmActives"]),

    editSupplierSilo(item) {
      this.editSilo = item;
      this.supplierDialog = true;
    },

    async remove() {
      if (this.selectedId == null) return;

      var tempSilo = this.getSiloById(this.selectedId);
      if (!tempSilo) return;

      if (tempSilo.isShared) await this.removeInvitedSilo(this.selectedId);
      else await this.deleteSilo(this.selectedId);

      this.removeDialog = false;
      this.selectedId = null;
      this.filteredSilos = [];
      this.filterSilos();
    },

    filterSilos() {
      if (this.searchTerm == null || this.searchTerm == "")
        this.filteredSilos = this.silos;

      let st = this.searchTerm.toLowerCase();
      this.filteredSilos = this.silos.filter(
        (d) =>
          (d.name && d.name.toLowerCase().search(st) != -1) ||
          (d.location && d.location.toLowerCase().search(st) != -1) ||
          (d.contents && d.contents.name.toLowerCase().search(st) != -1) ||
          (d.device.identifier &&
            d.device.identifier.toLowerCase().search(st) != -1) ||
          (d.metas &&
            d.metas.length > 0 &&
            d.metas.filter(
              (m) =>
                (m.key && m.key.toLowerCase().search(st) != -1) ||
                (m.value && m.value.toLowerCase().search(st) != -1) ||
                (m.value &&
                  m.key &&
                  (m.key + ": " + m.value).toLowerCase().search(st) != -1)
            ).length > 0)
      );
    },
  },

  async created() {
    await this.getSilos();
    this.filterSilos();
  },
};
</script>
