<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card>
      <v-card-title>{{ $t("silos.edit") }}</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="comment"
          :label="$t('silos.fields.comment')"
          prepend-inner-icon="mdi-comment"
          :disabled="loading"
        />

        <content-selector v-model="siloContentId" />
      </v-card-text>

      <v-card-actions>
        <v-spacer /><v-btn text :disabled="loading" @click="close">{{
          $t("common.close")
        }}</v-btn
        ><v-btn @click="save" :disabled="loading" color="primary" text>{{
          $t("common.save")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import ContentSelector from "../contents/ContentSelector.vue";
export default {
  components: { ContentSelector },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },

    silo: {
      type: Object,
      default: () => {
        return { siloContentId: null, comment: null };
      },
    },
  },

  model: {
    prop: "modelValue",
  },

  data() {
    return { loading: false, comment: null, siloContentId: null };
  },

  computed: {
    dialog: {
      get() {
        return this.modelValue;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {
    ...mapActions("silos", ["modifySupplierSilo"]),

    close() {
      this.$emit("close");
      this.dialog = false;
      this.comment = null;
      this.siloContentId = null;
      this.loading = false;
    },

    async save() {
      if (!this.silo.siloId) return;
      let id = this.silo.siloId;
      let payload = {
        comment: this.comment,
        siloContentId: this.siloContentId,
      };
      this.loading = true;

      await this.modifySupplierSilo({
        siloId: id,
        payload: payload,
      });

      this.loading = false;

      this.dialog = false;
      this.$emit("close");
    },
  },

  watch: {
    silo(val) {
      if (!val) {
        this.comment = null;
        this.siloContentId = null;

        return;
      }

      this.comment = val.comment;
      this.siloContentId = val.siloContentId;
    },

    dialog() {
      if (!this.silo) {
        this.comment = null;
        this.siloContentId = null;

        return;
      }

      this.comment = this.silo.comment;
      this.siloContentId = this.silo.siloContentId;
    },
  },
};
</script>