<template>
  <div class="progress">
    <div
      class="bar"
      :style="`width: ${localPrecentile}%; background: ${color}`"
    ></div>
  </div>
</template>

<script>
export default {
  name: "PrecentileIndicator",

  props: {
    precentile: {
      type: Number,
      default: 100,
    },
  },

  data() {
    return { localPrecentile: 100 };
  },

  computed: {
    color() {
      if (this.localPrecentile > 50) return "#53a653";
      else if (this.localPrecentile > 15) return "#f6d55c";
      else return "#b00020";
    },
  },

  methods: {
    cleanUpPrecentile(val) {
      if (val > 100) this.localPrecentile = 100;
      else if (val <= 0) this.localPrecentile = 0;
      else this.localPrecentile = val;
    },
  },

  created() {
    this.cleanUpPrecentile(this.precentile);
  },

  watch: {
    precentile(val) {
      this.cleanUpPrecentile(val);
    },
  },
};
</script>

<style lang="sass">
.progress
    width: 150px
    height: 20px
    border-radius: 5px
    border: 1px solid gray
    display: flex

    .bar
        height: 100%
        border-radius: 3px
        background: green
        overflow: hidden
</style>
